

















































































































import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import ImgView from '@/components/ImgView/index.vue'
import TipModal from '@/views/common/tip-modal.vue'
import { DatePicker } from 'element-ui'
Vue.use(DatePicker)
// Vue.use(Dialog)
@Component({
  components: {
    Table,
    Pagination,
    ImgView,
    TipModal
  }
})
export default class StaffList extends Vue {
  departmentId = ''
  searchModel = {
    key: 'time',
    value: null
  }
  searchModel2 = {
    key: 'name',
    value: ''
  }
  listLoading = false
  tableHeader = [
    {
      key: 'name',
      name: '姓名',
      width: '20%'
    },
    {
      key: 'deptName',
      name: '所属部门',
      width: '20%'
    },
    {
      key: 'faceImg',
      name: '人脸照',
      width: '20%',
      isSpecial: true
    },
    {
      key: 'recordTime',
      name: '录入时间',
      width: '20%'
    },
    {
      key: 'opt',
      name: '操作',
      width: '20%',
      isSpecial: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 10
  }
  imgUrl = ''
  // 部门
  departmentList = [] as any
  isShowDeleteTip = false
  deleteSatffId = ''

  mounted() {
    this.getDepartmentList()
    this.getStaffList()
  }

  getDepartmentList() {
    this.$api.property.getDepartmentList({ size: -1 }).then((res: any) => {
      if (res.data.success && res.data.code === '200') {
        this.departmentList = res.data.data
      }
    })
  }

  getStaffList() {
    this.listLoading = true
    this.$api.property
      .getStaffList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        deptId: this.departmentId,
        [this.searchModel2.key]: this.searchModel2.value,
        startTime:
          this.searchModel.key === 'time' && (this.searchModel as any).value
            ? (this.searchModel as any).value[0]
            : '',
        endTime:
          this.searchModel.key === 'time' && (this.searchModel as any).value
            ? (this.searchModel as any).value[1]
            : ''
      })
      .then((res: any) => {
        this.listLoading = false
        if (res.data.code === '200' && res.data.success) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  searchClick() {
    this.listQuery.page = 1
    this.getStaffList()
  }
  toAdd() {
    this.$router.push('/property/staffInfo')
  }

  editStaff(item: any) {
    this.$router.push({
      path: '/property/staffInfo',
      query: {
        id: item.id
      }
    })
  }

  deleteStaff(staff: any) {
    this.isShowDeleteTip = true
    this.deleteSatffId = staff.id
  }

  confirmDeleteStaff() {
    this.$api.property.deleteStaff(this.deleteSatffId).then((res: any) => {
      if (res.data.success && res.data.code === '200') {
        this.$message.success(res.data.msg)
        this.isShowDeleteTip = false
        this.getStaffList()
      }
    })
  }

  go2History(item: any) {
    this.$router.push({
      path: '/property/staff-history',
      query: {
        id: item.id
      }
    })
  }
  getEquipmentList() {
    console.log(this.tableData)
  }
  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }
  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }

  closeDialog() {
    this.isShowDeleteTip = false
  }
}
